<template>
    <section class="tables">
    
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Liste des magasins
              </div>
              <nav aria-label="breadcrumb" class=" float-right">
                <ol class="breadcrumb">
                  <router-link to="/addmagasin">
                    <li class="breadcrumb-item btn btn-primary">
                      Ajouter magasin
                    </li>
                  </router-link>
                </ol>
              </nav>
              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par code"
                  type="text"
                  v-model="search"
                />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">nom</th>
                      <th scope="col">adresse</th>
                      <th scope="col">categorie</th>
                      <th scope="col">capacite</th>
                      <th scope="col">emplacements</th>
                      <th scope="col">Action</th>
                   </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(magasin, index) in searchFunction" :key="index">
              
                      <td>{{ magasin.nom }}</td>
                      <td>{{ magasin.adresse }}</td>
                      <td>{{ magasin.categorie}}</td>
                      <td>{{ magasin.capacite}}</td>
                      <td>
                        <i
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
                          data-toggle="modal"
                          :data-target="'#test' + magasin._id"
                        >
                        </i>

                        <div
                          class="modal large"
                          :id="'test' + magasin._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                  <span style="font-size: 13px"
                                    >magasin=</span
                                  >
                                  <b>{{ magasin.nom }}</b>
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Capacité:<b>{{magasin.capacite}}</b></h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Code Emplacement</th>
                                      <th>Quantités</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        emplacement, index
                                      ) in magasin.emplacements"
                                      :key="index"
                                    >
                                      <td>
                                        #{{ emplacement.emplacement_id['code']}}
                                      </td>

                                      <td>  {{ emplacement.emplacement_id['capacite']}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button type="button" class="btn btn-primary">
                                    Save changes
                                  </button>
                                </div> -->
                            </div>
                          </div>
                        </div>


























                      </td>
                      
                      <td>
                        <i
                          class="mdi mdi-pen icone text-success"
                          title="Modifier l'objectif"
                          @click="choose(magasin._id)"
                        >
                        </i>
                        <i
                          class="mdi mdi-delete icone text-danger"
                          title="Supprimer l'emplacement"
                          @click.prevent="deletemagasin(magasin._id)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from '@/axios';
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  export default {
    name: "basicTables",
    data() {
      return {
        magasins: [],
        search: "",
      };
    },
  
    created() {
      this.getmagasins();
    },
    computed: {
      searchFunction() {
        return this.magasins.filter((item) => {
          return (
            item.nom
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          );
        });
      },
    },
    methods: {
      getmagasins() {
        HTTP
          .get("magasins/getmagasins")
          .then((response) => {
            this.magasins = response.data;
          })
          .then((err) => console.log(err));
      },
      choose(id) {
        this.$router.push("updatemagasin/" + id);
      },
      deletemagasin(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez supprimer la magasin",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
            HTTP
              .delete("magasins/deletemagasin/" + id)
              .then((response) => {
                //this.$htmlToPaper("printNow");
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "magasin supprimé",
                });
  
                this.getmagasins();
              });
          }
        });
      },
    },
  };
  </script>
  